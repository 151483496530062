/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { noop } from "lodash";
import React from "react";

import { ErrorMessageProps } from "./model";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 10010
  }
}));

export function ErrorMessages(props: ErrorMessageProps) {
  const {
    autoHideDuration = 5000,
    className,
    disableAutoHide,
    errors,
    onClose = noop
  } = props;

  const classes = useStyles();

  return (
    <Snackbar
      className={`${classes.root} ${className} marapp-qa-errormessages`}
      open={!!errors?.length}
      autoHideDuration={disableAutoHide ? null : autoHideDuration}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <Alert onClose={onClose} variant="filled" severity="error">
        {errors &&
          errors.map &&
          errors.map((error, index) => (
            <Typography key={index}>
              {error.title ? `${error.title}: ` : ``}
              {error.detail}
              {error.source && (
                <>
                  <br />
                  <code>
                    {error.source.pointer} {error.source.body}
                    {/* enable this in a debug mode? */}
                    {/* {' '}{Object.entries(error.source.parameter || {}).map(([k,v]) => (
                      `${k}=${v}`
                    ))
                  } */}
                  </code>
                </>
              )}
            </Typography>
          ))}
      </Alert>
    </Snackbar>
  );
}
