/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Fab from "@mui/material/Fab";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";

import HoverMenu from "material-ui-popup-state/HoverMenu";
import {
  usePopupState,
  bindMenu,
  bindHover,
  bindToggle,
} from "material-ui-popup-state/hooks";
import IconAccount from "@mui/icons-material/Person";
import IconAccountOutline from "@mui/icons-material/PersonOutline";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import compose from "lodash/fp/compose";
import noop from "lodash/noop";
import { getInitials } from "../../utils";
import { Elang } from "../../locales";
import { FeedbackDialog } from "../FeedbackDialog";

import { ExpansionIcon, TranslationService, IUser } from "@marapp/earth-shared";
import useTheme from "@mui/styles/useTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    right: 0,
    top: 0,
    zIndex: 10,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  authButton: {
    height: theme.spacing(4),
    minHeight: theme.spacing(4),
    width: theme.spacing(4),
  },
  menu: {
    minWidth: theme.spacing(28),
    maxWidth: theme.spacing(40),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  signOut: {
    textTransform: "lowercase",
  },
}));

const languageLabels = {
  [Elang.EN]: "English",
  [Elang.ES]: "Español",
  [Elang.FR]: "Français",
  [Elang.PT]: "Português",
  [Elang.RU]: "Pусский",
};

interface IProps {
  profileLinkProps: any;
  userData?: IUser;
  onLogin?: () => void;
  onLogout?: () => void;
  onSignUp?: () => void;
  onSubmitFeedback?: (values: {
    subject: string;
    message: string;
  }) => Promise<any>;
  isAuthenticated?: boolean;
  selected?: string;
}

export const UserMenu = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const anchorRef = useRef(null);
  const {
    isAuthenticated = false,
    onSubmitFeedback,
    onLogin = noop,
    onLogout = noop,
    onSignUp = noop,
    selected,
    profileLinkProps,
    userData,
  } = props;

  const popupState = usePopupState({ variant: "popover", popupId: "userMenu" });
  const popupStateLang = usePopupState({
    variant: "popover",
    popupId: "languageMenu",
  });

  const handleLogout = () => {
    const defaultLanguage = TranslationService.getDefaultLanguage();
    i18n.changeLanguage(defaultLanguage);
    popupState.close();
  };
  const initials = userData?.name && getInitials(userData.name);

  const selectedLanguage = i18n.language;

  // Collapse the language options when hiding the main menu
  useEffect(() => popupStateLang.setOpen(false), [popupState.isOpen]);

  return (
    <>
      <div
        className={`marapp-qa-useraccount ${classes.root}`}
        {...bindHover(popupState)}
      >
        <div>
          <Fab
            className={`${classes.authButton} marapp-qa-user-menu-button`}
            size="small"
            color="primary"
            ref={anchorRef}
          >
            {initials ? (
              initials
            ) : isAuthenticated ? (
              <IconAccount />
            ) : (
              <IconAccountOutline />
            )}
          </Fab>

          <HoverMenu
            classes={{ paper: classes.menu }}
            marginThreshold={Number.parseInt(theme.spacing(1))}
            {...bindMenu(popupState)}
            anchorEl={anchorRef.current}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {isAuthenticated ? (
              [
                <ListItem key="user-details">
                  <ListItemAvatar>
                    <Avatar>{initials}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body2" noWrap title={userData.email}>
                        {userData.email}
                      </Typography>
                    }
                    secondary={
                      <Link
                        className={classes.signOut}
                        onClick={compose(handleLogout, onLogout)}
                      >
                        {t("Sign Out")}
                      </Link>
                    }
                  />
                </ListItem>,
                <MenuItem
                  key="profile"
                  divider
                  selected={selected === "profile"}
                  {...profileLinkProps}
                >
                  {t("Profile")}
                </MenuItem>,
              ]
            ) : (
              <MenuItem onClick={onLogin}>{t("Sign in")}</MenuItem>
            )}

            <MenuItem {...bindToggle(popupStateLang)}>
              <ListItemText
                primary={t("Language")}
                secondary={languageLabels[selectedLanguage]}
              />
              <ExpansionIcon isActive={popupStateLang.isOpen} />
            </MenuItem>
            <Collapse in={popupStateLang.isOpen}>
              <MenuList>
                {Object.keys(languageLabels).map((key) => (
                  <MenuItem
                    key={key}
                    onClick={() => i18n.changeLanguage(key)}
                    selected={selectedLanguage === key}
                    className={classes.nested}
                  >
                    {languageLabels[key]}
                  </MenuItem>
                ))}
              </MenuList>
            </Collapse>
            {isAuthenticated && [
              <Divider key="divider" />,
              <MenuItem key="feedback" onClick={() => setIsFeedbackOpen(true)}>
                {t("Send feedback")}
              </MenuItem>,
            ]}
          </HoverMenu>
        </div>
      </div>

      <FeedbackDialog
        open={isFeedbackOpen}
        onCancel={() => setIsFeedbackOpen(false)}
        onSubmitFeedback={onSubmitFeedback}
      />
    </>
  );
};
