/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import Pencil from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import FocusTrap from "focus-trap-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorButton, ErrorMessages } from "@marapp/earth-shared";

import { InlineCardOverlay } from "./index";
import { noop } from "lodash";
import { Options as FocusTrapOptions } from "focus-trap";

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    height: "100%",
    position: "relative",
    wordWrap: "break-word",
  },
  editing: {
    zIndex: theme.zIndex.drawer + 2,
  },
  loading: {
    "& *": {
      pointerEvents: "none",
    },
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 2,
  },
}));

interface IOptionsBag {
  isEditing: boolean;
  isLoading: boolean;
  serverErrors: any[];
  setIsEditing: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
  setServerErrors: (value: boolean) => void;
}

export interface InlineCardProps {
  // Pass children directly or use render props to have access to the edit state
  children:
    | ((optionsBag: IOptionsBag) => React.ReactNode | React.ReactNodeArray)
    | React.ReactNode;
  render?: (optionsBag: IOptionsBag) => React.ReactNode;
  focusTrapOptions?: FocusTrapOptions;
  hideEditButton?: boolean;
  editButtonText?: string;
  onSubmit?: (
    e: any,
    setIsEditing: (value: boolean) => void,
    setIsLoading: (value: boolean) => void,
    setServerErrors: (value: boolean) => void
  ) => void;
  onCancel?: () => void;
  submitButtonText?: string;
  cancelButtonText?: string;
  submitButtonVariant?: string;
  submitButtonColor?: string;
  validForm?: boolean;
}

export function InlineEditCard(props: InlineCardProps) {
  const { t } = useTranslation();
  const {
    children,
    render,
    editButtonText = t("edit"),
    focusTrapOptions = { allowOutsideClick: true },
    hideEditButton = false,
    onSubmit = noop,
    onCancel = noop,
    submitButtonText = t("Save"),
    cancelButtonText = t("Cancel"),
    submitButtonVariant = "contained",
    submitButtonColor = "primary",
    validForm,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const classes = useStyles({
    isEditing,
    isLoading,
  });

  const optionsBag: IOptionsBag = {
    isEditing,
    isLoading,
    serverErrors,
    setIsEditing,
    setIsLoading,
    setServerErrors,
  };

  const handleCancel = () => {
    setIsEditing(false);
    setServerErrors(false);

    onCancel();
  };

  const isErrorVariant = submitButtonVariant === "error";

  const submitButtonProps = {
    className: "marapp-qa-actionsaveinline",
    children: submitButtonText,
    disabled: !validForm,
    type: "submit",
    size: "large",
    ...(isErrorVariant
      ? {
          variant: "contained",
        }
      : {
          color: submitButtonColor,
          variant: submitButtonVariant,
        }),
  };

  const SubmitButtonComponent = isErrorVariant ? ErrorButton : Button;

  const renderEditable = () => (
    <>
      <FocusTrap focusTrapOptions={focusTrapOptions}>
        <form
          onSubmit={(e) =>
            onSubmit(e, setIsEditing, setIsLoading, setServerErrors)
          }
        >
          {render(optionsBag)}
          <ErrorMessages
            errors={serverErrors}
            onClose={() => setServerErrors(null)}
          />

          <InlineCardOverlay />
          <Box display="flex">
            <Box mr={1}>
              <SubmitButtonComponent {...submitButtonProps} />
            </Box>
            <Button
              type="button"
              className="marapp-qa-actioncancelinline"
              onClick={handleCancel}
              color="primary"
              size="large"
              variant="outlined"
            >
              {cancelButtonText}
            </Button>
          </Box>
        </form>
      </FocusTrap>
    </>
  );

  const renderDefault = () => (
    <Stack
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {typeof children === "function" ? children(optionsBag) : children}
      </Box>
      {render && !hideEditButton && (
        <Box>
          <Hidden mdDown={true}>
            <Button
              type="button"
              className="marapp-qa-actioneditinline"
              onClick={(e) => setIsEditing(true)}
              size="small"
              variant="outlined"
            >
              {editButtonText}
            </Button>
          </Hidden>
          <Hidden mdUp={true}>
            <IconButton
              size="small"
              className="marapp-qa-actioneditinline"
              onClick={(e) => setIsEditing(true)}
            >
              <Pencil fontSize="small" />
            </IconButton>
          </Hidden>
        </Box>
      )}
    </Stack>
  );

  return (
    <div
      className={classnames("marapp-qa-inlineeditcard", classes.root, {
        [classes.editing]: isEditing,
        [classes.loading]: isLoading,
      })}
    >
      <Paper className="marapp-qa-inlineeditcard" elevation={isEditing ? 8 : 1}>
        <Box position="relative" p={2}>
          {isEditing ? renderEditable() : renderDefault()}

          {isLoading && (
            <Backdrop open={true} className={classes.backdrop}>
              <CircularProgress />
            </Backdrop>
          )}
        </Box>
      </Paper>
    </div>
  );
}
