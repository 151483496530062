import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    padding: "0 10px",
    "& .Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiToggleButton-label": {
      color: theme.palette.primary.main,
    },
  },
}));

interface ButtonProps {
  className?: string;
  onChange?: Function;
  defaultValue?: boolean;
  color?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
}

export const BooleanButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    className,
    size = "small",
    color,
    defaultValue = false,
    onChange,
  } = props;

  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  return (
    <span className={classes.root}>
      <ToggleButtonGroup value={value}>
        <ToggleButton
          color={color}
          size={size}
          onClick={() => setValue(true)}
          value={true}
        >
          {t("Yes")}
        </ToggleButton>
        <ToggleButton
          color={value ? color : "white"}
          size={size}
          onClick={() => setValue(false)}
          value={false}
        >
          {t("No")}
        </ToggleButton>
      </ToggleButtonGroup>
    </span>
  );
};
