/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import { Theme } from "@mui/material/styles";

// import type {} from "@mui/x-data-grid/themeAugmentation";
import "@mui/x-data-grid/themeAugmentation";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const COLORS = {
  UNBL_TEAL_LIGHT: "#7ecace",
  UNBL_TEAL_MID: "#0099A1",
  UNBL_TEAL_DARK: "#006b70",

  /*
    Generated from #0099A1 using
    https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
    UNBL_GREEN_600: "#00a159", // analogous
    UNBL_BLUE_900: "#0048a1", // analogous
    UNBL_INDIGO_900: "#0800a1", // triadic
    UNBL_CERISE_500: "#a10099", // triadic
    UNBL_SCARLET_900: "#a10800", // complementary
    UNBL_SCARLET_500: "#db3519", // complementary
  */

  TEXT_ERROR: "#db3519", // complementary, lighter

  GREY_0: "#FFFFFF",
  GREY_1: "#F8F9FA",
  GREY_2: "#E9ECEF",
  GREY_3: "#DEE2E6",
  GREY_4: "#CED4DA",
  GREY_5: "#ADB5BD",
  GREY_6: "#6C757D",
  GREY_7: "#495057",
  GREY_8: "#343A40",
  GREY_9: "#212529",
};

const BACKGROUND_COLOR = {
  default: COLORS.GREY_9,
  paper: COLORS.GREY_9,
};

const SPACING = 8;

export const globalStyleOverrides = {
  body: {
    background: BACKGROUND_COLOR.default,
    position: "relative",
    minHeight: "100vh",
    scrollbarWidth: "thin",
    scrollbarColor: `${COLORS.GREY_8} ${COLORS.GREY_9}`,
  },
  "*": {
    scrollbarWidth: "thin",
    scrollbarColor: `${COLORS.GREY_8} ${COLORS.GREY_9}`,
  },
  a: {
    color: "inherit",
    cursor: "pointer",
    textDecoration: "none",
  },
  svg: {
    verticalAlign: "middle",
  },
  "ul, li": {
    paddingLeft: "0",
  },
  "::-webkit-scrollbar": {
    width: 12,
  },
  "::-webkit-scrollbar-track": {
    background: COLORS.GREY_8,
  },
  "::-webkit-scrollbar-corner": {
    background: COLORS.GREY_8,
  },
  ":focus": {
    outline: "none",
  },
  "::-webkit-scrollbar-thumb ": {
    backgroundColor: COLORS.GREY_7,
    borderRadius: SPACING,
    border: `3px solid ${COLORS.GREY_8}`,
  },
};

export default (themeConfig) =>
  responsiveFontSizes(
    createTheme({
      spacing: SPACING,
      palette: {
        mode: "dark",
        primary: {
          // light: COLORS.GREY_1,
          // main: COLORS.GREY_2,
          // dark: COLORS.GREY_3
          light: COLORS.UNBL_TEAL_LIGHT,
          main: COLORS.UNBL_TEAL_MID,
          dark: COLORS.UNBL_TEAL_DARK,
        },
        secondary: {
          light: COLORS.GREY_1, // themeConfig?.colors?.primary?.light
          main: COLORS.GREY_2, // themeConfig?.colors?.primary?.main
          dark: COLORS.GREY_3, // themeConfig?.colors?.primary?.dark
        },
        text: {
          primary: COLORS.GREY_0,
        },
        error: {
          main: COLORS.TEXT_ERROR,
        },
        background: {
          ...BACKGROUND_COLOR,
        },
        grey: {
          50: COLORS.GREY_1,
          100: COLORS.GREY_2,
          200: COLORS.GREY_3,
          300: COLORS.GREY_4,
          400: COLORS.GREY_5,
          500: COLORS.GREY_6,
          600: COLORS.GREY_7,
          700: COLORS.GREY_8,
          800: COLORS.GREY_9,
          900: COLORS.GREY_9,
        },
      },
      typography: {
        h2: {
          textTransform: "uppercase",
        },
        subtitle1: {
          fontWeight: 500,
        },
        subtitle2: {},
      },
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              colorScheme: "dark",
              mode: "dark",
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              backgroundColor: COLORS.GREY_9,
              "& *": {
                backgroundColor: "inherit",
              },
            },
          },
        },
        // MuiDrawer: {
        //   styleOverrides: {
        //     paper: {
        //       color: COLORS.GREY_9
        //     }
        //   }
        // },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              // Set the contrasting text colour to be dark grey
              color: COLORS.GREY_9,
            },
            outlinedPrimary: {
              // We have lots of buttons that use this, but the primary colour
              // has poor contrast.
              color: COLORS.GREY_2,
              borderColor: `${COLORS.GREY_2}80`,
              ":hover": {
                borderColor: `${COLORS.GREY_2}`,
              },
            },
          },
        },
        MuiCardContent: {
          styleOverrides: {
            root: {
              "&:last-child": {
                paddingBottom: SPACING * 2,
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              backgroundColor: COLORS.GREY_1,
              color: COLORS.GREY_9,
            },
            deleteIcon: {
              color: COLORS.GREY_9,
              "&:hover": {
                color: COLORS.GREY_7,
              },
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: globalStyleOverrides,
        },
        MuiFab: {
          styleOverrides: {
            root: {
              backgroundColor: COLORS.GREY_8,
              color: COLORS.GREY_1,
              "&:hover": {
                backgroundColor: COLORS.GREY_7,
              },
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            gutters: {
              paddingLeft: SPACING * 2,
              paddingRight: SPACING * 2,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              backgroundColor: COLORS.GREY_9,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            textColorPrimary: {
              "&$selected": {
                color: COLORS.GREY_1,
              },
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              "&:nth-of-type(even)": {
                backgroundColor: `${COLORS.GREY_3}11`,
              },
              "& td": {
                borderBottom: 0,
              },
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: COLORS.GREY_7,
              boxShadow:
                "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            colorError: {
              color: COLORS.TEXT_ERROR,
            },
          },
        },
      },
    })
  );
