/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import React from "react";

import withStyles from "@mui/styles/withStyles";
import blue from "@mui/material/colors/blue";

import classnames from "classnames";

import renderHTML from "react-render-html";

const styles = theme => ({
  root: {
    "& h1": {
      marginTop: "0.5em",
      marginBottom: "0em",
      ...theme.typography.h1
    },
    "& h2": {
      marginTop: "0.5em",
      marginBottom: "0em",
      ...theme.typography.h2
    },
    "& h3": {
      marginTop: "0.5em",
      marginBottom: "0em",
      ...theme.typography.h3
    },
    "& h4": {
      marginTop: "0.5em",
      marginBottom: "0em",
      ...theme.typography.h4
    },
    "& h5": {
      marginTop: "0.5em",
      marginBottom: "0em",
      ...theme.typography.h5
    },
    "& h6": {
      marginTop: "0.5em",
      marginBottom: "0em",
      ...theme.typography.h6
    },
    "& p": {
      ...theme.typography.body1
    },
    "& a": {
      color: blue["200"],
      textDecoration: "underline"
    },
    "& ol, ul": {
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      "& li": {
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        position: "relative",
        "&:last-child": {
          marginBottom: 0
        },
        "& > ol, > ul": {
          marginTop: theme.spacing(2)
        }
      }
    },
    "& strong, b": {
      fontWeight: 500
    },
    "& > *": {
      marginTop: theme.spacing(2),
      "&:first-child": {
        marginTop: 0
      }
    }
  }
});

interface HTMLProps {
  html: string;
  classes?: any;
  className?: string;
}

class HTMLComponent extends React.Component<HTMLProps> {
  static defaultProps = {
    className: ""
  };

  render() {
    const { classes, html, className } = this.props;

    return (
      <div
        className={classnames("marapp-qa-html", classes.root, {
          [className]: !!className
        })}
      >
        {renderHTML(html)}
      </div>
    );
  }
}

export const Html = withStyles(styles)(HTMLComponent);
