/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import useTheme from "@mui/styles/useTheme";
import IconUp from "@mui/icons-material/ExpandMore";
import IconDown from "@mui/icons-material/ExpandLess";
import React from "react";

// for compat with material-ui-toggle-icon
interface IProps {
  iconOff?: React.ReactElement;
  iconOn?: React.ReactElement;
  isOn: boolean;
  [any: string]: any;
}

export const StateTransitionIcon = (props) => {
  const theme = useTheme();

  const transition = theme.transitions.create(["visibility", "clip-path"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });

  const cssPosition = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  };

  const { iconsByState = {}, value = "default" } = props;

  return (
    <Box sx={{ position: "relative", display: "inline-block" }}>
      {Object.entries(iconsByState).map(([iconValue, iconComponent]) => (
        <Box
          key={iconValue}
          sx={
            iconValue === String(value)
              ? {
                  transition,
                  clipPath: "polygon(100% -100%, 100% 100%, -100% 100%)",
                  visibility: "visible",
                  display: "flex",
                  justifyContent: "center",
                }
              : {
                  transition,
                  ...cssPosition,
                  clipPath: "polygon(100% 100%, 100% 100%, 100% 100%)",
                  visibility: "hidden",
                }
          }
        >
          {iconComponent}
        </Box>
      ))}
    </Box>
  );
};

// export const ToggleIcon = (props: IProps) => {
//   const { iconOn = <IconUp />, iconOff = <IconDown />, isOn, ...rest } = props;
//
//   return isOn ? iconOn : iconOff;
// };

export const ToggleIcon = (props: IProps) => {
  const { iconOn = <IconUp />, iconOff = <IconDown />, isOn, ...rest } = props;

  return (
    <StateTransitionIcon
      iconsByState={{
        true: iconOn,
        false: iconOff,
      }}
      value={Boolean(isOn)}
    />
  );
};
