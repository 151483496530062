/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

interface IProps {
  title: string;
  subtitle?: string;
}

export function DropdownItem(props: IProps) {
  const { title, subtitle } = props;

  return (
    <Box display="flex" alignItems="center" key={`${title}-${subtitle}`}>
      <Typography>{title}</Typography>

      {!!subtitle && (
        <>
          <Typography color="textSecondary" component="span">
            &#x20;&#x2022;&#x20;
          </Typography>
          <Typography color="textSecondary" component="span">
            {subtitle}
          </Typography>
        </>
      )}
    </Box>
  );
}
