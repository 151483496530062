/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import isObject from "lodash/isObject";
import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import ButtonBase from "@mui/material/ButtonBase";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Popover from "@mui/material/Popover";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(7)
  },
  colorPreview: {
    borderRadius: 4,
    height: theme.spacing(7),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    width: "100%",
    minWidth: theme.spacing(7),
    "& > div": {
      borderRadius: 4,
      height: "100%",
      width: "100%"
    }
  }
}));

export interface Color {
  r?: string;
  g?: string;
  b?: string;
  a?: string;
}

export interface ColorInputProps {
  disabled?: boolean;
  className?: string;
  label?: string;
  [key: string]: any;
}

const getBackgroundColor = (color: Color) => {
  return isObject(color)
    ? `rgba(${color.r},${color.g},${color.b},${color.a})`
    : color;
};

export const ColorInput = React.forwardRef(
  (props: ColorInputProps, ref: any) => {
    const { label, className, disabled } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const openColorPicker = event => {
      setAnchorEl(event.currentTarget);
    };

    const closeColorPicker = () => {
      setAnchorEl(null);
    };

    const onColorChange = value => {
      props.onChange(value?.rgb || value);
    };

    useEffect(() => {
      if (props.defaultValue && !props.value) {
        onColorChange(props.defaultValue);
      }
    }, []);

    return (
      <>
        <FormControl
          className={classes.root}
          fullWidth={true}
          variant="outlined"
        >
          <InputLabel shrink={!!props.value}>{label}</InputLabel>

          <div className={`${className} ${classes.colorPreview}`}>
            <ButtonBase
              component="div"
              style={{ backgroundColor: getBackgroundColor(props.value) }}
              disabled={disabled}
              onClick={openColorPicker}
            />

            <Popover
              open={!!anchorEl}
              disableEnforceFocus={true}
              anchorEl={anchorEl}
              onClose={closeColorPicker}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <div>
                <SketchPicker
                  color={props.value}
                  onChange={color => onColorChange(color)}
                />
              </div>
            </Popover>
          </div>
        </FormControl>
      </>
    );
  }
);
