import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#2CD787",
  },
  error: {
    "& .MuiInputBase-root": {
      color: theme.palette.error.main,
    },
  },
  disableSlider: {
    // reset cursor
    cursor: "default",
    "& .MuiSlider-thumb": {
      // make it rectangular, so it's not seen as interactive
      borderRadius: 0,
      marginLeft: 0,
      width: 2,
      boxShadow: "none",
      "&.MuiSlider-active": {
        boxShadow: "none",
      },
    },
  },
  cap: {
    width: "100%",
    height: 2,
    display: "block",
    position: "relative",
    top: 15,
    borderRadius: 1,
    backgroundColor: theme.palette.grey[900],
  },
  rail: {
    opacity: 0.38,
    height: 2,
  },
  input: {
    width: 72,
    height: 40,
    "& .MuiOutlinedInput-inputMarginDense": {
      // default is 10.5, make a little extra room for our format suffix
      paddingLeft: 10,
      paddingRight: 2,
    },
  },
}));

interface SliderProps {
  className?: string;
  onChange?: Function;
  min: number;
  max: number;
  start: number;
  step?: number;
  size?: "pico" | "nano" | "mini" | "small" | "medium" | "large";
}

export const SliderInput = (props: SliderProps) => {
  const classes = useStyles();
  const {
    className,
    onChange,
    min = 0,
    max = 100,
    start = 25,
    step,
    size,
  } = props;
  const [value, setValue] = React.useState(start);
  const [error, setError] = React.useState(false);

  const handleSliderChange = (event, newValue) => {
    if (handleLimit({ floatValue: newValue })) {
      setValue(newValue);
    }
  };

  const handleKeyPress = (event) => {
    // arrow up/down adjusts value incrementally
    let delta = event.shiftKey ? step : 1;
    let newValue = value;
    if (event.key === "ArrowUp") {
      newValue = value + delta;
    }
    if (event.key === "ArrowDown") {
      newValue = value - delta;
    }
    if (newValue !== value && handleLimit({ floatValue: newValue })) {
      setValue(newValue);
    }
  };

  const validateInputChange = (event) => {
    let newValue = Number(event.target.value.replace("%", "")) || 0;
    if (handleLimit({ floatValue: newValue })) {
      setError(false);
      setValue(newValue);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  const handleLimit = ({ floatValue }) =>
    floatValue >= min && floatValue <= max;

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <NumberFormat
            customInput={TextField}
            className={[classes.input, error && classes.error].join(" ")}
            value={value}
            suffix="%"
            allowNegative={false}
            isNumericString={true}
            decimalScale={1}
            fixedDecimalScale={false}
            displayType="input"
            margin="dense"
            variant="outlined"
            inputProps={{
              min,
              max,
              step,
              onBlur: validateInputChange,
              onKeyDown: handleKeyPress,
              "aria-labelledby": "input-slider",
            }}
          />{" "}
        </Grid>
        <Grid item xs>
          <span className={classes.cap} />
          <Slider
            value={typeof value === "number" ? value : 0}
            size={size}
            min={min}
            max={max}
            onChange={handleSliderChange}
            onKeyDown={handleKeyPress}
            aria-labelledby="input-slider"
            style={{
              left: `${min}%`,
              width: `${max - min}%`,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
