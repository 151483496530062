import React, {
  ComponentClass,
  useState,
  MouseEventHandler,
  useEffect,
  forwardRef,
} from "react";
import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from "react-select";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from "react-sortable-hoc";
import useTheme from "@mui/styles/useTheme";

import { IResponseMany, IQueryMany } from "../../interfaces";

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

// See example at https://react-select.com/advanced#sortable-multiselect

const SortableMultiValue = SortableElement((props: MultiValueProps<any>) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) as ComponentClass<
  Props<any, true> & SortableContainerProps
>;

interface SortableSelectProps {
  value?: any[];
  onChange?: (selectedOptions: OnChangeValue<any, true>) => void;
  className?: string;
  label?: string;
  placeholder?: string;
  getOptionLabel: (option: any) => string;
  getOptionValue: (option: any) => string;
  fetcher: (query: IQueryMany) => IResponseMany;
  query: (search: string) => IQueryMany;
}

const SortableSelectComponent = forwardRef(
  (
    {
      fetcher,
      query,
      value = [],
      className,
      label,
      placeholder,
      getOptionLabel,
      getOptionValue,
      onChange,
    }: SortableSelectProps,
    ref
  ) => {
    const theme = useTheme();

    // function to convert provided options to options that are a valid SortableSelect prop
    const toSelectOptions = (options) => {
      return (
        options?.map((option, index) => ({
          ...option,
          label: getOptionLabel(option),
          value: getOptionValue(option),
          index: index,
        })) || []
      );
    };

    const [searchInput, setSearchInput] = useState("");
    const { data, awaitMore, nextPage } = fetcher(query(searchInput));
    const [options, setOptions] = useState(toSelectOptions(data));
    const [selected, setSelected] = useState<readonly any[]>(
      toSelectOptions(value)
    );

    const handleMenuScrollToBottom = () => {
      if (awaitMore) {
        nextPage();
      }
    };

    useEffect(() => {
      const mappedOptions = toSelectOptions(data);
      if (JSON.stringify(mappedOptions) !== JSON.stringify(options)) {
        setOptions(mappedOptions);
      }
    }, [data, getOptionLabel, getOptionValue]);

    const handleChange = (selectedOptions: OnChangeValue<any, true>) => {
      setSelected(selectedOptions);
      if (onChange) {
        onChange(selectedOptions);
      }
    };

    const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
      const newValue = arrayMove(selected, oldIndex, newIndex);
      setSelected(newValue);
      if (onChange) {
        onChange(newValue);
      }
    };

    return (
      <SortableSelect
        className={`marapp-qa-select ${className}`}
        useDragHandle
        helperClass="sortableHelper"
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        isMulti
        options={options}
        value={selected}
        onChange={handleChange}
        innerRef={ref}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
        }}
        closeMenuOnSelect={false}
        label={label}
        placeholder={placeholder}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onInputChange={(inputValue) => {
          setSearchInput(inputValue);
        }}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: theme.palette.grey["700"],
            color: "white",
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: theme.palette.grey["600"],
            color: "white",
          }),
          option: (styles, { isFocused }) => ({
            ...styles,
            color: "white",
            backgroundColor: isFocused
              ? theme.palette.grey["500"]
              : theme.palette.grey["600"],
          }),
          multiValue: (styles) => {
            return {
              ...styles,
              backgroundColor: theme.palette.grey["600"],
              zIndex: theme.zIndex.modal,
            };
          },
          multiValueRemove: (styles) => ({
            ...styles,
            ":hover": {
              backgroundColor: theme.palette.grey["500"],
            },
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: "white",
          }),
          input: (styles) => ({
            ...styles,
            color: "white",
          }),
        }}
      />
    );
  }
);

export default SortableSelectComponent;
