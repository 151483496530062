import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconPlus from "@mui/icons-material/Add";
import IconMinus from "@mui/icons-material/Remove";
import React from "react";

interface IZoomControl {
  viewport: { zoom?: number; maxZoom?: number; minZoom?: number };
  onClick: (zoom: number) => void;
  zoom?: number;
}

const ZoomControl = (props: IZoomControl) => {
  const { viewport, onClick } = props;
  const { maxZoom, minZoom, zoom } = viewport;

  const increaseZoom = (e) => {
    e.stopPropagation();

    onClick(Math.min(zoom + 1, maxZoom));
  };

  const decreaseZoom = (e) => {
    e.stopPropagation();

    onClick(Math.max(zoom - 1, minZoom));
  };

  return (
    <Paper elevation={8} sx={{ boxShadow: 2, textAlign: "center" }}>
      <ButtonGroup
        orientation="vertical"
        className="marapp-qa-mapzoom"
        variant="text"
        color="secondary"
        size="small"
        sx={{
          "& .MuiButtonGroup-grouped": { minWidth: "auto" },
        }}
      >
        <Button
          fullWidth
          className="marapp-qa-zoomin"
          disabled={zoom >= maxZoom}
          onClick={increaseZoom}
          sx={{ minWidth: "auto" }}
        >
          <IconPlus fontSize="small" />
        </Button>

        <Button
          fullWidth
          className="marapp-qa-zoomout"
          disabled={zoom <= minZoom}
          onClick={decreaseZoom}
          variant="text"
          sx={{ minWidth: "auto" }}
        >
          <IconMinus fontSize="small" />
        </Button>
      </ButtonGroup>
    </Paper>
  );
};

export default ZoomControl;
