import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import IconDown from "@mui/icons-material/ExpandMore";
import React from "react";

interface IProps {
  icon?: React.FunctionComponent | React.ComponentClass;
  isActive: boolean;
  [any: string]: any;
}

const useStyles = makeStyles((theme) => ({
  toggle: {
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toggleOn: {
    transform: "rotate(180deg)",
  },
}));

export const ExpansionIcon = (props: IProps) => {
  const { icon = IconDown, isActive, ...rest } = props;
  const classes = useStyles();

  return React.createElement(icon, {
    // @ts-ignore
    className: classNames({
      [classes.toggle]: true,
      [classes.toggleOn]: isActive,
    }),
    ...rest,
  });
};
