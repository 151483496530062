import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import makeStyles from "@mui/styles/makeStyles";
import useTheme from '@mui/styles/useTheme';

import React from "react";
import classnames from "classnames";

interface IListItemProps {
  className?: string;
  onClick?: (arg?: any) => any;
  primary: string | React.ReactNode;
  secondary?: string | React.ReactNode;
  secondaryAction?: React.ReactNode;
  selected?: boolean;
  linkTo?: string;
  linkComponent?: any;
  noWrap?: boolean;

  [any: string]: any;
}

const useStyles = makeStyles(theme => ({
  // needed for ul>li structure (on Layers tab)
  container: {
    "&:last-child div": {
      borderBottom: "none"
    }
  }
}));

export function ListItem(props: IListItemProps) {
  const {
    className,
    linkComponent,
    linkTo,
    onClick,
    primary,
    secondary,
    secondaryAction,
    selected,
    noWrap = true,
    ...rest
  } = props;

  const theme = useTheme();
  const classes = useStyles(props);

  const listItemProps: any = {
    onClick,
    sx:{
      borderBottom: `1px solid ${theme.palette.divider}`,
      "&:last-child": {
        borderBottom: "none"
      }
    },
    classes: { container: classes.container },
    className: classnames(className, "marapp-qa-listitem"),
    selected,

    // ToDo change this condition once switching to an unique router throughout map and admin
    component: linkComponent || "div",
    ...(linkTo && {
      to: linkTo
    })
  };

  return (
    <ListItemButton {...listItemProps} {...rest}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box sx={{ flex: 1 }}>
          <ListItemText
            primary={primary}
            secondary={secondary}
            primaryTypographyProps={{
              className: "marapp-qa-list-item-title",
              noWrap: noWrap
            }}
            secondaryTypographyProps={{
              className: "marapp-qa-list-item-description",
              noWrap: noWrap
            }}
          />
        </Box>

        {secondaryAction && <Box sx={{ flex: "none" }}>{secondaryAction}</Box>}
      </Box>
    </ListItemButton>
  );
}
