import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { bindMenu } from "material-ui-popup-state/hooks";
import React from "react";

interface IMenuOption {
  label: string | React.ReactNode;
  onClick?: (arg?: any) => any;
  disableCloseOnOptionClick?: boolean;
  [any: string]: any;
}

interface IProps {
  anchorOrigin?: any;
  className?: string;
  transformOrigin?: any;
  popupState?: any;
  options?: IMenuOption[];
  [any: string]: any;
}

export const Menu = React.forwardRef((props: IProps, ref: any) => {
  const {
    className,
    popupState,
    options,
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "right"
    },
    transformOrigin = {
      vertical: "top",
      horizontal: "right"
    },
    ...rest
  } = props;

  return (
    <MuiMenu
      ref={ref}
      {...bindMenu(popupState)}
      className={`${className} marapp-qa-dropdown marapp-qa-dropdown-${popupState.popupId}`}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onBlur={() => popupState.close()}
      classes={{
        ...rest.classes,
        paper: `${rest?.classes?.paper}`
      }}
      {...rest}
    >
      {options?.map((option, index) => {
        const { disableCloseOnOptionClick, label, onClick, ...rest } = option;

        return (
          <MenuItem
            key={index}
            onClick={ev => {
              !disableCloseOnOptionClick && popupState.close();

              onClick && onClick(ev);
            }}
            {...rest}
          >
            {label}
          </MenuItem>
        );
      })}
    </MuiMenu>
  );
});
