/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

interface IProps {
  value: any;
  selected: boolean;
  onClick: (value: any) => {};
  children?: any;
  [key: string]: any;
}

export const Option = React.forwardRef((props: IProps, ref: any) => {
  const { value, selected, onClick, children, ...rest } = props;
  const classes = useStyles();

  return (
    <MenuItem
      ref={ref}
      title={children}
      className={classnames(classes.root)}
      onClick={() => onClick(value)}
      selected={!!selected}
      {...rest}
    >
      {children}
    </MenuItem>
  );
});
