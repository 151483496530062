import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";
import IconRecenter from "@mui/icons-material/GpsFixed";
import React from "react";

const styles = (theme) => ({
  root: {
    boxShadow: theme.shadows[2],
  },
});

export interface IRecenter {
  className?: string;
  classes?: any;
  onClick: () => void;
}

class RecenterControl extends React.PureComponent<IRecenter> {
  public static defaultProps = {
    className: null,
  };

  public render() {
    const { className, classes, onClick } = this.props;

    const classNames = classnames("marapp-qa-recentercontrol", classes.root, {
      [className]: !!className,
    });

    return (
      <Paper elevation={8} sx={{ textAlign: "center" }}>
        <Button
          fullWidth
          onClick={onClick}
          color="secondary"
          sx={{ minWidth: "auto" }}
          size="small"
          variant="text"
        >
          <IconRecenter fontSize="small" />
        </Button>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RecenterControl);
